import TeamsCalendarList from "./overview/list";


const currentPath = document.body.getAttribute("data-current-path");
if (currentPath && currentPath === "teams/calendar") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "index") {
        new TeamsCalendarList();
    }
}
